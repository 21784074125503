var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1"},[_vm._v("Taloyhtiöt / Kiinteistöt")]),_c('v-card',{staticClass:"mt-2"},[(!_vm.isGroupMode())?_c('v-card-title',[_c('v-spacer'),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/condominium/create"}},[(_vm.isAuthorized('condominium', 'create'))?_c('v-btn',{attrs:{"color":"info"}},[_vm._v("Uusi taloyhtiö")]):_vm._e()],1)],1):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Hae kaikista kentistä...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col')],1)],1),_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.condominiums,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.switchToAccount(item.createdBy, '/condominiums')}}},[_c('strong',[_vm._v(_vm._s(item.createdBy.name)+" ")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(!_vm.isGroupMode())?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/condominium/overview/" + (item._id))}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")])]):_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',[_vm._v(_vm._s(item.address))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('condominium', 'read'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.isAuthorized('condominium', 'delete'))?_c('v-icon',{attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e(),(_vm.isAuthorized('condominium', 'create'))?_c('v-icon',{attrs:{"title":"Luo vuokrakohde","small":""},on:{"click":function($event){_vm.setDefaultApartment();
              _vm.$router.push(("/condominium/" + (item._id) + "/create-apartment")).catch(function () {});}}},[_vm._v("mdi-plus-circle-outline")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei taloyhtiöitä")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }